const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://a2htvtmhjd.execute-api.us-west-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://79ujck0uw5.execute-api.us-west-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.testing.saas.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.7.0',
    HOSTNAME: 'https://targets.testing.saas.forwoodsafety.com'
  }
};

export default config;